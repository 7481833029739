import "./index.less";
import { Spin, Steps, Tabs } from "antd";
import PickTopic from "./steps/PickTopic";
import { BookingContext, useCreateBookingContext } from "./data/BookingContext";
import PickStaff from "./steps/PickStaff";
import Scheduling from "./steps/Scheduling";
import Payment from "./steps/Payment";
import Completed from "./steps/Completed";
import { useUrlState } from "@/utils/util";
import { useRequest } from "ahooks";
import { getConsultation } from "@/adminApp/apis/consultation-api";
import { ConsultationStatus } from "@/adminApp/pages/admin_consultation/scheduling/shared";

const components = {
  PickTopic,
  PickStaff,
  Scheduling,
  Payment,
  Completed,
};

const ConsultationBooking = () => {
  const [params, setParams] = useUrlState({ id: undefined });
  const value = useCreateBookingContext();
  const { state } = value;
  const steps = [
    {
      heading: "Choose Your Topic",
      headingDesc:
        "Choose from a range of topics you’d like to discuss. Don’t see what you’re looking for? Write in a topic and we’ll see if we can help!",
      title: "Choose Your Topic",
      width: 230,
      tab: "PickTopic",
    },
    {
      heading: "Select a Staff Member",
      headingDesc:
        "Browse our team of experienced professionals and find the perfect match for your needs.",
      title: "Pick a consultant",
      width: 200,
      tab: "PickStaff",
    },
    {
      heading: "Pick a Time Slot",
      headingDesc: "Pick a date and time that works for you.",
      title: "Scheduling",
      width: 160,
      tab: "Scheduling",
    },
    state.consultation.status === ConsultationStatus.PENDING_SCHEDULE
      ? {
          heading: "Confirmation",
          title: "Payment & Confirmation",
          width: 250,
          headingDesc: "Pick a date and time that works for you.",
          tab: "Scheduling",
        }
      : {
          heading: "Payment",
          title: "Payment & Confirmation",
          width: 250,
          headingDesc:
            "Secure your booking with our simple and secure payment process. You will only be charged if we accept your consultation request.",
          tab: "Payment",
        },
    {
      heading: "Completed",
      headingDesc:
        "Get ready for your consultation! Keep an eye on your inbox for details about your meeting as it approaches.",
      title: "Completed",
      width: 160,
      tab: "Completed",
    },
  ];
  const currentStep = steps[state.step];
  const consultationRequest = useRequest(() => getConsultation(params.id!), {
    ready: state.consultation?.id === undefined && !!params.id,
    refreshDeps: [params.id],
    onSuccess: (result) => {
      if (
        result.id &&
        [
          ConsultationStatus.PENDING_PAYMENT,
          ConsultationStatus.PENDING_SCHEDULE,
        ].includes(result.status)
      ) {
        state.consultation = result;
        state.staff = result.staff;
        state.bookingUrl = result.bookUrl;
        state.eventTypeUri = result.eventTypeUri;
        state.step = 3;
      } else {
        setParams({ id: undefined });
      }
    },
  });

  return (
    <BookingContext.Provider value={value}>
      <div className="staff-scheduling">
        <h1>{currentStep.heading}</h1>
        <div>{currentStep.headingDesc}</div>
        <Steps current={state.step} style={{ margin: "36px 0px" }}>
          {steps.map((item) => (
            <Steps.Step
              title={item.title}
              key={item.title}
              style={{ width: item.width, flex: "auto" }}
            />
          ))}
        </Steps>
        <Tabs tabBarStyle={{ display: "none" }} activeKey={currentStep.tab}>
          {Object.entries(components).map(([key, Component]) => (
            <Tabs.TabPane key={key}>
              <Component />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      {consultationRequest.loading && (
        <Spin className="global-spin-container"></Spin>
      )}
    </BookingContext.Provider>
  );
};

export default ConsultationBooking;
