import { useBookingContext } from "../data/BookingContext";
import { Button } from "antd";
import { ReactComponent as SchedulingSuccessIcon } from "@/images/icon_scheduling_success.svg";
import { formatDateTime } from "@/adminApp/pages/admin_consultation/scheduling/shared";
import { useHistory } from "react-router-dom";

const Completed = () => {
  const { state } = useBookingContext();
  const history = useHistory();
  return (
    <div className="steps-content">
      <div className="steps-content-head">
        <div className="steps-content-head-title">Consultation Requested</div>
        <div className="steps-action">
          {state.step > 0 && (
            <Button
              type="primary"
              style={{ margin: "0 8px" }}
              onClick={() =>
                history.push("/dashboard/scheduling?mode=calendar")
              }
            >
              View my calendar
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center font-roboto">
        <SchedulingSuccessIcon className="mt-24 mb-12" />
        <div className="mb-4 text-[32px] font-[900]">
          Consultation Requested
        </div>
        <div className="mb-32 text-[16px] w-[374px] text-center">
          {`You have successfully requested a consultation with ${
            state.staff.name
          } on ${formatDateTime(
            state.consultation.startTime
          )}. This appointment has NOT been confirmed yet. You will receive an email notification after we have reviewed your request. Your payment will only be processed if we confirm this appointment.`}
        </div>
      </div>
    </div>
  );
};

export default Completed;
