import { updateConsultationStatus } from "@/adminApp/apis/consultation-api";
import { useRequest } from "ahooks";
import { Form, Input, Modal, message, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { ConsultationStatus, declineReasons } from "./shared";

type Props = {
  record?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: () => void;
};

const DeclineModal = ({ open, setOpen, record, refresh }: Props) => {
  const [form] = useForm();
  const updateStatusRequest = useRequest(updateConsultationStatus, {
    manual: true,
  });
  const onSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const template = declineReasons.find(
      (item) => item.value === values.reason
    )?.template;
    try {
      await updateStatusRequest.runAsync({
        id: record?.id,
        status: ConsultationStatus.DECLINED,
        template,
        ...values,
      });
      message.success("Consultation declined successfully!");
      setOpen(false);
      refresh?.();
    } catch (error: any) {
      message.error(error?.message || error?.statusText || error);
    }
  };
  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [form, open]);
  return (
    <Modal
      className="add-consultant-topic-modal"
      title={
        <span className="text-[24px] font-bold leading-[1.5]">
          Decline Consultation Schedule
        </span>
      }
      width={670}
      visible={open}
      okText="submit"
      onOk={onSubmit}
      onCancel={() => setOpen(false)}
      okButtonProps={{
        loading: updateStatusRequest.loading || updateStatusRequest.loading,
      }}
      destroyOnClose
    >
      <div className="mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </div>
      <Form layout="vertical" form={form}>
        <Form.Item
          label="REASON"
          name="reason"
          rules={[
            {
              required: true,
              message: "Please select a reason",
            },
          ]}
        >
          <Select
            maxLength={100}
            placeholder="Select a reason"
            options={declineReasons}
          />
        </Form.Item>
        <Form.Item label="NOTE" name="note">
          <Input maxLength={1000} placeholder="Type something" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeclineModal;
