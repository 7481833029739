import {
  getCalendlyEntity,
  updateConsultation,
} from "@/adminApp/apis/consultation-api";
import { useRequest } from "ahooks";
import { Modal, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { ConsultationStatus } from "./shared";

type Props = {
  record?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: () => void;
  setRecord: (record: any) => void;
};

const RescheduleModal = ({
  open,
  setOpen,
  record,
  setRecord,
  refresh,
}: Props) => {
  const [url, setUrl] = useState<string | undefined>();
  const updateRequest = useRequest(updateConsultation, {
    manual: true,
  });
  const [eventTypeViewed, setEventTypeViewed] = useState(false);
  useCalendlyEventListener({
    onEventTypeViewed: () => setEventTypeViewed(true),
  });
  const rescheduleRequest = useRequest(
    async (skip = false) => {
      const res = await getCalendlyEntity(record?.invitee);
      if (res?.resource?.rescheduled) {
        const newInvitee = await getCalendlyEntity(res?.resource?.new_invitee);
        await updateRequest.runAsync({
          id: record?.id,
          eventUri: newInvitee?.resource?.event,
          invitee: res?.resource?.new_invitee,
          status: ConsultationStatus.RESCHEDULED,
        });
        message.success("Consultation rescheduled successfully!");
        refresh();
        setOpen(false);
        setRecord(undefined);
      } else {
        if (!skip) {
          message.warning("You haven't rescheduled yet!");
          return;
        }
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (record?.invitee) {
      getCalendlyEntity(record?.invitee).then((res) => {
        setUrl(res?.resource?.reschedule_url);
      });
    } else {
      setUrl(undefined);
    }
  }, [record?.invitee]);

  // const submitSchedule = async () => {
  //   await rescheduleRequest.refreshAsync();
  // };

  useEffect(() => {
    if (!open) {
      setEventTypeViewed(false);
    } else {
      setTimeout(() => {
        setEventTypeViewed(true);
      }, 3000);
    }
  }, [open]);

  return (
    <Modal
      className="add-consultant-topic-modal"
      title={
        <span className="text-[24px] font-bold leading-[1.5]">
          Reschedule Consultation
        </span>
      }
      afterClose={() => setEventTypeViewed(false)}
      width="80vw"
      visible={open}
      onCancel={async () => {
        setOpen(false);
        rescheduleRequest.runAsync(true);
        setRecord(undefined);
      }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      footer={null}
      // onOk={submitSchedule}
      // okText="Submit Reschedule"
      // okButtonProps={{ loading: rescheduleRequest.loading }}
    >
      <Spin
        spinning={!url || !eventTypeViewed}
        className="flex items-center justify-center bg-white !max-h-none"
      >
        <InlineWidget
          styles={{
            height: 700,
            width: "100%",
            minHeight: 300,
            marginTop: eventTypeViewed ? -20 : 20,
          }}
          pageSettings={{}}
          url={url || ""}
        />
      </Spin>
    </Modal>
  );
};

export default RescheduleModal;
