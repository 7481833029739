import { getConsultations } from "@/adminApp/apis/consultation-api";
import { localStorageGet } from "@/utils/storage";
import { useRequest } from "ahooks";
import { Badge, Popover, Spin } from "antd";
import generateCalendar, {
  CalendarMode,
} from "antd/lib/calendar/generateCalendar";
import dayjs, { Dayjs } from "dayjs";
import React, { memo, useState } from "react";
import { Consultation } from "./data/typings";
import { ConsultationStatus } from "@/adminApp/pages/admin_consultation/scheduling/shared";
import BookingDetailModal from "./BookingDetailModal";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

type Props = {
  duration?: string;
  admin?: string;
  type: "user" | "admin";
};

const BookingsCalendar: React.FC<Props> = ({ duration, admin, type }) => {
  const [mode, setMode] = useState<CalendarMode>("month");
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState<Consultation | undefined>();
  const [startDate, setStartDate] = useState<string>(
    dayjs().startOf("month").toISOString()
  );
  const [selected, setSelected] = useState<string>();
  const userId = type === "user" ? localStorageGet("info")?.id : undefined;
  const listRequest = useRequest(
    () => {
      return getConsultations({
        userId,
        startDate: dayjs(startDate).add(-1, mode).startOf(mode).toISOString(),
        endDate: dayjs(startDate).add(1, mode).endOf(mode).toISOString(),
        duration,
        staffId: admin,
        status: [
          ConsultationStatus.REQUESTED,
          ConsultationStatus.CONFIRMED,
          ConsultationStatus.RESCHEDULED,
        ].join(","),
      });
    },
    {
      refreshDeps: [userId, startDate, mode, duration, admin],
      debounceWait: 100,
    }
  );

  const listRender = (items: Consultation[]) => {
    const list = items.map((item: Consultation) => {
      return (
        <div
          key={item.id}
          className="cursor-pointer hover:bg-black/10 duration-200 px-2 w-full py-0 rounded text-ellipsis whitespace-nowrap overflow-hidden my-[2px]"
          onClick={() => {
            setRecord(item);
            setOpen(true);
          }}
        >
          <Badge
            key={item.id}
            status={
              [
                ConsultationStatus.CONFIRMED,
                ConsultationStatus.RESCHEDULED,
              ].includes(item.status as any)
                ? "success"
                : "default"
            }
          />
          Meeting with{" "}
          {type === "user" ? item?.staff?.name : item.artistUser.fullName} -{" "}
          {item.name}
        </div>
      );
    });
    return (
      <div className="flex items-start flex-col absolute top-2 w-10/12">
        {list.slice(0, 3)}
        {list.length > 3 && (
          <Popover
            overlayClassName="z-10"
            overlayInnerStyle={{ borderRadius: 4 }}
            content={
              <div className="flex items-start flex-col min-w-[120px]">
                {list}
              </div>
            }
            trigger="click"
          >
            <a className="ml-2 block">+{items.length - 3} more</a>
          </Popover>
        )}
      </div>
    );
  };

  const monthCellRender = (value: Dayjs) => {
    const items = listRequest.data?.list.filter((item: Consultation) => {
      return dayjs(item.startTime).isSame(+value, "month");
    });
    if (!items?.length) {
      return null;
    }
    return listRender(items);
  };

  const dateCellRender = (value: Dayjs) => {
    const items = listRequest.data?.list.filter((item: Consultation) => {
      return dayjs(item.startTime).isSame(value.toISOString(), "day");
    });
    if (!items?.length) {
      return null;
    }

    return listRender(items);
  };

  return (
    <Spin spinning={listRequest.loading}>
      <Calendar
        className="px-4"
        value={dayjs(selected)}
        onChange={(date) => {
          setSelected(date.toISOString());
          setStartDate(date.startOf(mode).toISOString());
        }}
        onPanelChange={(date, mode) => {
          setStartDate(date.startOf(mode).toISOString());
          setMode(mode);
        }}
        mode={mode}
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
      />
      <BookingDetailModal
        open={open}
        setOpen={setOpen}
        setConsultation={setRecord}
        consultation={record}
        type={type}
      />
      {type === "user" && (
        <div className="flex justify-start mt-10">
          <div className="flex items-center  mr-4">
            <div className="size-[10px] bg-[#10AC57] mr-2"></div>Your booking
          </div>
          <div className="flex items-center">
            <div className="size-[10px] bg-[#797F84] mr-2"></div>Tentative
            meeting
          </div>
        </div>
      )}
    </Spin>
  );
};

export default memo(BookingsCalendar);
