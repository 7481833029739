import { useAntdTable, useRequest } from "ahooks";
import Empty from "./Empty";
import "./index.less";
import { getConsultations, getStaffs } from "@/adminApp/apis/consultation-api";
import { localStorageGet } from "@/utils/storage";
import { Button, Dropdown, Input, Menu, Radio, Select, Table, Tag } from "antd";
import { useState } from "react";
import { ColumnsType } from "antd/lib/table";
import {
  ConsultationStatus,
  formatDateTime,
  formatDuration,
  statusMap,
} from "@/adminApp/pages/admin_consultation/scheduling/shared";
import RescheduleModal from "@/adminApp/pages/admin_consultation/scheduling/RescheduleModal";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as SchedulingIcon } from "@/images/icon_scheduling_calendar.svg";
import { ReactComponent as SchedulingListIcon } from "@/images/icon_scheduling_list.svg";
import { StaffStatus } from "@/adminApp/pages/admin_consultation/staff/shared";
import { uniq } from "lodash";
import BookingsCalendar from "./BookingsCalendar";
import { useUrlState } from "@/utils/util";
import DatePicker from "@/components/DatePicker";
import { textRender } from "@/components/Ellipsis";

const ConsultationScheduling = () => {
  const [params, setParams] = useUrlState({ mode: "list" });
  const [admin, setAdmin] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [record, setRecord] = useState();
  const [keyword, setKeyword] = useState("");
  const userInfo = localStorageGet("info");
  const history = useHistory();
  const staffsRequest = useRequest(() =>
    getStaffs({ status: StaffStatus.ACTIVE })
  );
  const { tableProps, refresh } = useAntdTable(
    ({ current, pageSize }) => {
      return getConsultations({
        current,
        pageSize,
        keyword,
        userId: userInfo.id,
        staffId: admin,
        duration,
        startDate,
        endDate,
      });
    },
    {
      refreshDeps: [keyword, admin, duration, startDate, endDate, endDate],
    }
  );
  const column: ColumnsType<any> = [
    {
      title: "ADMIN NAME",
      dataIndex: ["staff", "name"],
      className: "!pl-8 min-w-[130px]",
    },
    {
      title: "ARTIST NAME",
      className: "min-w-[130px]",
      render: (_, record) =>
        `${record.artistUser.firstName} ${record.artistUser.lastName}`,
    },
    {
      title: "DURATION",
      dataIndex: "duration",
      className: " min-w-[110px]",
      render: (duration) => formatDuration(duration),
    },
    {
      title: "TOPIC",
      dataIndex: "topic",
    },
    {
      title: "DATE & TIME",
      dataIndex: "startTime",
      className: "!min-w-[150px]",
      render: (duration) => formatDateTime(duration),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: ConsultationStatus) => (
        <Tag color={statusMap[text] ? statusMap[text]?.color : "gray"}>
          {statusMap[text] ? statusMap[text]?.text : text}
        </Tag>
      ),
    },
    {
      title: "ARTIST NOTE",
      dataIndex: "description",
      render: textRender,
      className: "!min-w-[110px] max-w-[200px]",
    },
    {
      title: "ADMIN NOTE",
      dataIndex: "note",
      render: textRender,
      className: "!min-w-[110px] max-w-[200px]",
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => {
        return (
          <Dropdown
            trigger={["click"]}
            overlayStyle={{ zIndex: 5 }}
            overlay={() => (
              <Menu className="admin-consultation-staff-menu">
                <Menu.Item
                  disabled={
                    ![
                      ConsultationStatus.RESCHEDULED,
                      ConsultationStatus.CONFIRMED,
                    ].includes(row.status)
                  }
                  key="reschedule"
                  onClick={() => {
                    setRecord(row);
                    setRescheduleOpen(true);
                  }}
                >
                  Reschedule
                </Menu.Item>
                {row.status === ConsultationStatus.PENDING_PAYMENT && (
                  <Menu.Item
                    key="pay"
                    onClick={() => {
                      history.push(
                        `/dashboard/scheduling/booking?id=${row.id}`
                      );
                    }}
                  >
                    Pay Now
                  </Menu.Item>
                )}
                {row.status === ConsultationStatus.PENDING_SCHEDULE && (
                  <Menu.Item
                    key="schedule"
                    onClick={() => {
                      history.push(
                        `/dashboard/scheduling/booking?id=${row.id}`
                      );
                    }}
                  >
                    Schedule Now
                  </Menu.Item>
                )}
              </Menu>
            )}
          >
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <FilterMore />
            </span>
          </Dropdown>
        );
      },
    },
  ];
  const isEmpty =
    !tableProps.dataSource?.length &&
    !tableProps.loading &&
    duration === "" &&
    admin === "" &&
    keyword === "" &&
    !startDate &&
    !endDate;
  const showCalendar = params.mode === "calendar";
  const durations =
    staffsRequest.data?.list?.flatMap((item: any) =>
      item.serviceRates?.map((i: any) => i.duration)
    ) ?? [];

  const handleSchedule = () => {
    history.push("/dashboard/scheduling/booking");
  };

  return (
    <div className="staff-scheduling">
      <div className="flex justify-between items-start">
        <h1>Consultation Scheduling</h1>
        <Button hidden={isEmpty} onClick={handleSchedule} type="primary">
          Schedule a Consultation meeting
        </Button>
      </div>
      {!isEmpty && (
        <div className="flex justify-between items-start">
          <div>
            <div className="range-picker-wrap field-form-items">
              <span>date</span>
              <DatePicker.RangePicker
                disabled={params.mode === "calendar"}
                onChange={(value) => {
                  if (value?.[0]) {
                    setStartDate(value[0].startOf("day").toISOString());
                  }
                  if (value?.[1]) {
                    setEndDate(value[1].endOf("day").toISOString());
                  }
                }}
              />
            </div>
            <div className="flex my-7">
              <div className="relative">
                <Select
                  className="custom-select-render filter-by-admin"
                  options={[
                    { label: "All", value: "" },
                    ...(staffsRequest.data?.list?.map((item: any) => ({
                      label: item.name,
                      value: item.id,
                    })) ?? []),
                  ]}
                  value={admin}
                  onChange={(value) => setAdmin(value)}
                />
                <div className="absolute top-[1px] leading-[30px] left-2 text-[14px] pointer-events-none">
                  Filter by Admin Name:
                </div>
              </div>
              <div className="relative ml-4">
                <Select
                  className="custom-select-render filter-by-duration"
                  defaultValue=""
                  options={[
                    { label: "All", value: "" },
                    ...uniq(durations).map((d: any) => ({
                      label: formatDuration(d),
                      value: d,
                    })),
                  ]}
                  value={duration}
                  onChange={(value) => setDuration(value)}
                />
                <div className="absolute top-[1px] leading-[30px] left-2 text-[14px] pointer-events-none">
                  Filter by Duration:
                </div>
              </div>
            </div>
          </div>
          <Radio.Group
            value={params.mode}
            onChange={(e) => setParams({ mode: e.target.value })}
          >
            <Radio.Button className="size-14 p-4" value="list">
              <SchedulingListIcon className="size-6" />
            </Radio.Button>
            <Radio.Button className="size-14 p-4" value="calendar">
              <SchedulingIcon className="size-6" />
            </Radio.Button>
          </Radio.Group>
        </div>
      )}
      {isEmpty ? (
        <Empty />
      ) : showCalendar ? (
        <BookingsCalendar duration={duration} admin={admin} type="user" />
      ) : (
        <Table
          className="table-container"
          title={() => (
            <div className="flex pl-4">
              <span>Bookings</span>
              <Input.Search
                onSearch={(keyword: string) => {
                  setKeyword(keyword);
                }}
                placeholder="Search"
              />
            </div>
          )}
          rowKey="id"
          columns={column}
          sticky
          scroll={{
            scrollToFirstRowOnChange: true,
            x: "max-content",
          }}
          {...tableProps}
          pagination={{ ...tableProps.pagination, showSizeChanger: true }}
          loading={tableProps.loading}
        />
      )}
      <RescheduleModal
        open={rescheduleOpen}
        setOpen={setRescheduleOpen}
        setRecord={setRecord}
        refresh={refresh}
        record={record}
      />
    </div>
  );
};

export default ConsultationScheduling;
