import { Button, Tag, message, Spin, Empty, Tooltip } from "antd";
import { useBookingContext } from "../data/BookingContext";
import { useRequest } from "ahooks";
import { getStaffs } from "@/adminApp/apis/consultation-api";
import classNames from "classnames";
import { StaffStatus } from "@/adminApp/pages/admin_consultation/staff/shared";
import ReadMore from "@/components/ReadMore";
import {
  formatDuration,
  getRate,
} from "@/adminApp/pages/admin_consultation/scheduling/shared";

const PickStaff = () => {
  const { state } = useBookingContext();
  const staffsRequest = useRequest(() =>
    getStaffs({
      topic: state.topic,
      status: StaffStatus.ACTIVE,
    })
  );
  const onNext = () => {
    try {
      if (!state.staff?.id) {
        throw new Error("Please select a staff");
      }
      if (!state.eventTypeUri) {
        document.querySelector(".pick-duration")?.scrollIntoView({
          behavior: "smooth",
        });
        throw new Error("Please select a duration");
      }
      state.step++;
    } catch (error: any) {
      message.error(error?.message ?? error ?? "Internal Error");
    }
  };
  const onSelectStaff = (item: any) => {
    if (state.staff.id !== item.id) {
      state.staff = item;
      state.bookingUrl = "";
      state.eventTypeUri = "";
      setTimeout(() => {
        document.querySelector(".pick-duration")?.scrollIntoView({
          behavior: "smooth",
        });
      }, 0);
    }
  };
  const onSelectDuration = (service: any) => {
    state.eventTypeUri = service.uri;
    state.bookingUrl = "";
  };
  const noStaff = !staffsRequest.data?.list?.length && !staffsRequest.loading;
  return (
    <div className="steps-content">
      <div className="steps-content-head">
        <div className="steps-content-head-title">
          Who do you want to talk to?
        </div>
        <div className="steps-action">
          {state.step > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => state.step--}>
              Previous
            </Button>
          )}
          <Button type="primary" onClick={onNext}>
            Next
          </Button>
        </div>
      </div>
      <Spin spinning={staffsRequest.loading} className="bg-white">
        {noStaff ? (
          <Empty className="py-40" description={false} />
        ) : (
          <div className="step-content-container pick-staff-step">
            <div className="staff-list flex !flex-wrap w-full justify-start items-start">
              {staffsRequest.data?.list?.map((item: any) => {
                return (
                  <div
                    className={classNames(
                      "staff-item mb-6 mr-6 flex flex-col items-start",
                      {
                        selected: state?.staff?.id === item?.id,
                      }
                    )}
                    key={item.id}
                    onClick={() => onSelectStaff(item)}
                  >
                    <div className="avatar">
                      <img
                        className="w-full h-full object-cover"
                        src={item.photo}
                        alt="admin avatar"
                      />
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="email font-normal">{item.email}</div>
                    <div className="min-h-[229px]">
                      <div className="description mb-auto font-normal">
                        <ReadMore text={item.description} maxHeight={75} />
                      </div>
                      <div className="specializing">
                        <div className="topic-tag flex-wrap">
                          {item.topics?.split?.(",").map((topic: string) => {
                            return (
                              <Tag className="mb-2" key={topic}>
                                {topic}
                              </Tag>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <Button
                      className="request-btn !mt-4"
                      type="primary"
                      onClick={() => {
                        setTimeout(() => {
                          onNext();
                        }, 100);
                      }}
                    >
                      Select consultant
                    </Button>
                  </div>
                );
              })}
            </div>
            {state.staff?.id !== undefined && (
              <>
                <div className="section-title">Pick a duration</div>
                <div className="pick-duration">
                  {state.staff.serviceRates
                    ?.filter((service: any) => !service.hide)
                    .map((service: any, index: number) => {
                      const rate = getRate(service);
                      if (rate === undefined) {
                        return null;
                      }
                      return (
                        <div
                          className={classNames("duration-option", {
                            selected: state.eventTypeUri === service.uri,
                          })}
                          key={index + service.uri}
                          onClick={() => onSelectDuration(service)}
                        >
                          <Tooltip
                            key={index + service.uri}
                            overlayInnerStyle={{
                              width:
                                service.description?.length > 400
                                  ? 500
                                  : undefined,
                              padding: 16,
                            }}
                            title={service.description}
                          >
                            <div className="duration-option-inner">
                              <div className="duration-time">
                                {formatDuration(service.duration)}
                              </div>
                              <div className="duration-fee">${rate}</div>
                            </div>
                          </Tooltip>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        )}
      </Spin>
    </div>
  );
};

export default PickStaff;
